<template>
  <div>
    <el-main>
      <search-log @submit="submitSearch" />
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="operationContent" label="操作内容"  />
        <el-table-column prop="operationPath" label="操作路径"  />
        <el-table-column
          prop="operatingModeDisplay"
          label="操作方式"
        />
        <el-table-column prop="operator" label="操作人"  />
        <el-table-column prop="createTime" label="操作时间"  />
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        v-model:page="query.pageNum"
        v-model:limit="query.pageSize"
        @pagination="fetchData"
      />
    </el-main>
  </div>
</template>

<script>
import SearchLog from "@/view/system_log/Search.vue";
import Pagination from "@/components/Pagination/index.vue";
import { isResOK } from "@/api/response";
import { listSystemLog } from "@/api/system_log";
export default {
  name: "systemLog",
  components: {
    Pagination,
    SearchLog,
  },
  data() {
    return {
      data: [],
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      listSystemLog(Object.assign({}, this.query)).then((res) => {
        if (isResOK(res)) {
          this.data = res.rows;
          this.total = res.total;
        }
      });
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({}, { pageNum: this.query.pageNum }, search);
      this.fetchData();
    },
  },
};
</script>

<style scoped></style>
