<template>
  <div>
    <el-form ref="formRef" :inline="true" :model="formState" label-width="85px" label-position="left">
      <el-row>
        <el-col :span="7">
          <el-form-item label="用户姓名或登录账号" prop="operator" label-width="140px">
            <el-input
                v-model="formState.operator"
                placeholder="请输入用户姓名或登录账号"
                clearable
                style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="操作时间" prop="queryTime">
            <el-date-picker
                style="width: 240px"
                v-model="formState.queryTime"
                type="date"
                placeholder="请选择操作时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                mini="2020-01-01"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button type="warning" @click="handleReset">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SearchLog",
  emits: ["submit"],
  data() {
    return {
      formState: {},
    };
  },
  created() {
  },
  methods: {
    handleSearch() {
      this.$emit("submit", this.formState);
    },
    handleReset() {
      this.$refs.formRef.resetFields();
      this.areaId = undefined;
      this.$emit("submit", this.formState);
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-right: 15px;
}
</style>
