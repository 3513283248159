import request from '@/utils/request'

// 查询系统日志列表
export function listSystemLog(query) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/system_log/list',
    method: 'get',
    params: query
  })
}

// 查询系统日志详细
export function getSystemLog(id) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/system_log/' + id,
    method: 'get'
  })
}

// 新增系统日志
export function addSystemLog(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/system_log',
    method: 'post',
    data: data
  })
}

// 修改系统日志
export function updateSystemLog(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/system_log',
    method: 'put',
    data: data
  })
}

// 删除系统日志
export function delSystemLog(id) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/system_log/' + id,
    method: 'delete'
  })
}
